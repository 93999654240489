import React from 'react';
import TypeIt from "typeit-react";

function CodeBlock() {

    return (
        <ul className="code">
            <li className="code__item"><span className="text text_color_orange">const</span>&nbsp;<span
                className="text text_color_gray">site</span>&nbsp;=&nbsp;<span>{`{`}</span></li>
            <li className="code__item code__item_highlighted">&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="text text_color_purple">message</span>:&nbsp;<span className='text text_color_green'>'
                <TypeIt
                    getBeforeInit={(instance) => {
                        instance.type("Охуе").pause(750).delete(4).pause(500).type("<span class='text text_style_wavy'>ohuel&nbsp;</span>").pause(1000).delete(2).pause(1000).type("<span class='text text_style_wavy'>l.ru</span>").type(' — самый народный домен рунета');
                        return instance;
                    }}
                />'</span><span
                className="text text_color_orange">,</span>&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li className="code__item">&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="text text_color_purple">contacts</span>:&nbsp;<span>{`{`}</span></li>
            <li className="code__item">&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="code__item code__item_bordered"> </span>&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="text text_color_purple">email</span>:&nbsp;<span
                className='text text_color_green'>'<a className="link" href="mailto:ya@ohuel.ru">ya@ohuel.ru</a>'</span>
            </li>
            <li className="code__item">&nbsp;&nbsp;&nbsp;&nbsp;}<span className="text text_color_orange">,</span></li>
            <li className="code__item">&nbsp;&nbsp;&nbsp;&nbsp;<span className="text text_color_purple">am<span
                className="text text_style_wavy">IreallyOhuel</span></span>:&nbsp;<span
                className="text text_color_orange">true</span><span className="text text_color_orange">,</span></li>
            <li className="code__item">&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="text text_color_purple">subdomains</span>:&nbsp;<span>{`{`}</span></li>
            <li className="code__item">&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="code__item code__item_bordered"> </span>&nbsp;&nbsp;&nbsp;&nbsp;<span
                className="text text_color_purple">spa</span>:&nbsp;<span
                className='text text_color_green'>'<a className="link" href="https://spa.ohuel.ru" target="_blank">https://spa.ohuel.ru</a>'</span>
            </li>
            <li className="code__item">}<span className="text text_color_orange">;</span></li>
        </ul>
    );

}

export default CodeBlock;