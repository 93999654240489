import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import useVH from 'react-viewport-height';
import '../blocks/root/root.css';
import CodeBlock from './CodeBlock';

function App() {
    const location = useLocation();
    const [currentLocation] = useState(location);

    useEffect(() => {
        window.ym(85687177, 'hit', location.pathname);
    }, [location, currentLocation]);
    useVH();
    return (
        <Routes>
            <Route
                path={'/'}
                element={<CodeBlock />}
            />
        </Routes>

    );
}

export default App;